import React from "react";
import img1 from "../assets/Ocean Freight/container-operation-port-series.jpg"
function News (){
    


    return(

        <div className="Maps bg-main-ins">
         <div className='mx-auto col-12 pt-5 text-center' >
         <h1 className='mx-auto text-center pb-1 highlighted-text fs-2 fw-medium'  > News & stories </h1>
         </div>
        <div className="container mx-auto p-5">
            <div className="row mx-auto">
                <div className="col-md-4 col-12 mx-auto text-center ">
                <div className="col-md-9 col-12 mx-auto text-center ">
                    <img src={img1} className="img-thumbnail" />
                    <p className="text-primary fs-5 fw-bold mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics</p>
                    <p className="text-dark fw-thin mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics to Gain a Competitive Edge in Logistics</p>
                </div>
                </div>
                <div className="col-md-4 col-12 mx-auto text-center ">
                <div className="col-md-9 col-12 mx-auto text-center ">
                    <img src={img1} className="img-thumbnail" />
                    <p className="text-primary fs-5 fw-bold mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics</p>
                    <p className="text-dark fw-thin mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics to Gain a Competitive Edge in Logistics</p>
                </div>
                </div>
                <div className="col-md-4 col-12 mx-auto text-center ">
                <div className="col-md-9 col-12 mx-auto text-center ">
                    <img src={img1} className="img-thumbnail" />
                    <p className="text-primary fs-5 fw-bold mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics</p>
                    <p className="text-dark fw-thin mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics to Gain a Competitive Edge in Logistics</p>
                </div>
                </div>
            </div>
        </div>


        </div>



    )
}


export default News