import React, { useState } from 'react';
import logo from '../assets/PageMatterial/mersLogo.png';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

function Headers() {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("header").classList.add("header_scrolling");
    document.getElementById("header").classList.remove("bg-transparent");
  } else {
    document.getElementById("header").classList.remove("header_scrolling");
    document.getElementById("header").classList.add("bg-transparent");
  }
}

  return (
    <nav id='header' className="navbar navbar-expand-lg navbar-primary bg-transparent fixed-top" >
      <div className="container">
        {/* Logo */}
        <a className="navbar-brand" href="/">
          <img
            src={logo}
            alt="Logo"
            width="250"
            height="65"
            className="d-inline-block align-top"
          />
        </a>

        {/* Toggle Button for Mobile */}
        <button
          className="navbar-toggler bg-primary"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto mobilenav">
            <li className="nav-item mx-1">
              <a
                className={`nav-link text-light btn btn-outline-primary  ${
                  activeButton === 'home' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('home')}
                href="/" 
               
              >
                Anasayfa
              </a>
            </li>
            <li className="nav-item mx-1">
              <a
                className={`nav-link text-light btn btn-outline-primary  ${
                  activeButton === 'about' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('about')}
                href="#hakkimizda"
              >
                Hakkımızda
              </a>
            </li>
            <li className="nav-item mx-auto">
              <Dropdown as={ButtonGroup}>
                <Button
                  className={`nav-link text-light btn btn-outline-primary  p-0 m-1 ${
                    activeButton === 'services' ? 'active' : ''
                  }`}
                  onClick={() => handleButtonClick('services')}
                >
                  <a
                    className="text-light mx-2"
                    style={{ textDecoration: 'none' }}
                    href="#hizmetler"
                  >
                    Hizmetler
                  </a>
                  <Dropdown.Toggle split className="" id="hizmetler" />
                  <Dropdown.Menu className="bg-primary">
                    <Dropdown.Item className="text-dark" href="#/action-1">
                      Air Freight
                    </Dropdown.Item>
                    <Dropdown.Item className="text-dark" href="#/action-2">
                      Ground Freight
                    </Dropdown.Item>
                    <Dropdown.Item className="text-dark" href="#/action-3">
                      Ocean Freight
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Button>
              </Dropdown>
            </li>
            <li className="nav-item mx-1">
              <a
                className={`nav-link text-light btn btn-outline-primary  ${
                  activeButton === 'partners' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('partners')}
                href="#ortaklar"
              >
                Markalarımız
              </a>
            </li>
            <li className="nav-item mx-1">
              <a
                className={`nav-link text-light btn btn-outline-primary  ${
                  activeButton === 'career' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('career')}
                href="#kariyer"
              >
                Kariyer
              </a>
            </li>
            <li className="nav-item mx-1">
              <a
                className={`nav-link text-light btn btn-outline-primary  ${
                  activeButton === 'news' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('news')}
                href="#haberler"
              >
                Haberler
              </a>
            </li>
            <li className="nav-item mx-1">
              <a
                className={`nav-link text-light btn btn-outline-primary  ${
                  activeButton === 'contact' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('contact')}
                href="#iletisim"
              >
                Bize Ulaşın
              </a>
            </li>
          </ul>
          
        </div>
      </div>
    </nav>
  );
}

export default Headers;
