import React  from "react";



function Video (){




return(
<div className="bg-main-ins  ">
<div className="container mx-auto p-md-5  "  >
<iframe className="px-md-5 video-youtube mb-5 mt-5 " 
    src="https://www.youtube.com/embed/4VpFUPvKYMM?si=yTKYk1cGbbTPe-4r"
    title="Mers Video"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen 



></iframe>
 
</div>

</div>

)

}

export default Video