import React from "react";
import $ from 'jquery';
import map from '../assets/PageMatterial/map.png'
import '../bars.css'

function Counters() {
    
    React.useEffect(() => {
        $('.counter-value').each(function () {
            const targetValue = $(this).text() || "0"; 
            $(this).prop('Counter', 0).animate({
                Counter: parseInt(targetValue, 10) 
            }, {
                duration: 5500,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                    console.log("sayım")
                }
            });
        });
    }, []);

    const data = [
        { label: "OEM", value: 80, color: "#4a90e2" },
        { label: "Automotive", value: 75, color: "#5ba4e5" },
        { label: "Goods", value: 40, color: "#78c1e8" },
        { label: "Paper ", value: 35, color: "#99d8eb" },
        { label: "Industrial", value: 45, color: "#c7eaf0" },
        { label: "Chemicals", value: 35, color: "#dff1f6" },
        { label: "Building", value: 25, color: "#f4f9fb" },
        { label: "Fashion", value: 30, color: "#f4f9fb" },
        { label: "Building", value: 15, color: "#f4f9fb" },
        { label: "Events", value: 10, color: "#f4f9fb" },
      ];
      


    return (
        <div className="bg-image-map  mx-auto d-flex align-middle justify-center">
        <div className=" row container mx-auto">

        <div className="col-md-4 p-0 m-0">

        <div className=" row col-12 col-md-12 pt-2 mt-5 mx-auto" >            
            <div className="bg-transparent pt-3  ">
            <div className="row mx-auto">
                <img                 
                src={map}
                alt="map"
                className="img-fluid "                
                 />
                
                <div className="row"> 
                 <div className="col-6 mx-auto "> 
                    <div className="counter-value counter-num text-center ">18 </div>
                    <p className="text-light text-center fw-bold fs-md-4 " style={{marginTop:"-20px", textShadow:"#00000059 1px 1px 10px;"}}>Countries </p>
                 </div>

                 <div className="col-6 mx-auto "> 
                    <div className="counter-value counter-num text-center ">29 </div>
                    <p className="text-light text-center fw-bold fs-md-4" style={{marginTop:"-20px"}}>Locations </p>
                 </div>
                </div>                 
            </div>   


            <div className="row  align-bottom mt-5 pt-4 " >             
                <div className="col-4 container mt-0" >
                <img                 
                src="https://www.ewals.com/img/employees.png"
                alt="employees"
                className="img-fluid pt-md-1 pb-md-2 "                
                 />
                </div>
                <div className="col-7   employees-num" >
                    <p className="counter-value text-start">2000</p>
                </div>
                <div className="col-12 row mx-auto  " >
                    <p className="text-light text-start fw-medium fs-4 col-12 ">Employees</p>
                    <p className="text-primary bg-main text-start fw-medium fs-6 col-4"> Office 1.200</p>
                    <p className="text-primary bg-main-ins text-start fw-medium fs-6 col-4"> Office 1.200</p>
                    <p className="text-primary bg-main text-start fw-medium fs-6 col-4"> Office 1.200</p>
                </div>
            </div> 
            
             </div>            
            </div>

    
             
                    
      
        </div>

        <div className=" row col-12 col-md-5" > 
            <div className="bg-transparent p-1 m-2 ">
                 <div className="bg-transparent col-md-9 p-1 m-5 "> 
                 <p className="text-light fw-bolder fs-4 m-0 p-0">International goods transport</p>
                 <p className="text-light m-0 p-0 ">60% intermodal</p>
                 <p className="text-light m-0 p-0 ">40% Road</p>
                 <hr className="text-light border-3"></hr>
                 <p className="text-light m-0 p-0 lh-1">148,000 Rail/Short-sea transfers used yearly</p>                 
                 <hr className="text-light border-3"></hr>
                 <p className="text-light m-0 p-0 lh-1">Across approx. 165 unique Rail/hort-sea connections</p>
                 </div>
             </div>
            <div className="bg-transparent p-md-1 m-md-2 mb-md-5 "> 
            <div class="chart-container">
                <div className="chart-container">
                <h2>% of Total TO Per Industry</h2>
                <div className="chart">
                    {data.map((item, index) => (
                    <div
                        key={index}
                        className="bar"
                        style={{
                        "--value": item.value,
                        "--color": item.color,
                        }}
                        data-value={item.label}
                    >
                    
                    </div>
                    ))}
                </div>
                </div>
            </div>
            </div>  
        </div>

        <div className="row col-12 col-md-3 mx-auto p-0" >
            <div className="bg-transparent p-1 m-2 mt-5 ">
                    <div className="bg-transparent  p-1 m-0  mt-md-4   ">
                    <p className="counter-value employees-num ">3050</p>
                    <p className="fw-thin fs-md-4 m-0 p-0 text-light">Loads per day</p>
                     </div>
                </div>
                <div className="bg-transparent p-1 m-2 ">
                    
                    
                <div className="row  align-bottom " >             
                <div className="col-4 container d-flex align-middle " >
                <img                 
                src="https://www.ewals.com/img/trailer.png"
                alt="employees"
                className="img-fluid pt-md-1 pb-md-2"                
                 />
                </div>
                <div className="col-7  align-middle  employees-num" >
                    <p className="counter-value text-start m-0 p-0 d-flex">4400</p>
                </div>
                <div className="col-12 row mx-auto  " >
                    <p className="text-light text-start fw-thin fs-md-4 col-12 ">Trailers</p>
                    <p className="text-light text-start fw-medium fs-md-6 col-12 p-0 m-0"> 1.050 Mega Huckepack XL trailers</p>
                    <p className="text-light text-start fw-medium fs-md-6 col-12 p-0 m-0"> 3.350 Mega Huckepack XLS trailers</p>

                </div>
            </div> 


                </div>
                <div className="bg-transparent p-1 m-2 ">
                <div className="row  align-bottom  " >             
                
                <div className="col-md-5 col-5  align-bottom employees-num" >
                    <p className="counter-value text-start mx-md-3 mx-3 p-0">2050</p>
                </div>
                <div className="col-md-5 col-5 container " >
                <img                 
                src="https://www.ewals.com/img/truck.png"
                alt="employees"
                className="img-fluid pt-md-1 pb-md-2 p-md-0 "                
                 />
                </div>
                <div className="col-12 row mx-auto  " >
                    <p className="text-light text-start fw-thin fs-4 col-12 ">Trucks</p>
                    <p className="text-light text-start fw-medium fs-6 col-12 p-0 m-0"> 1.050 Mega Huckepack XL trailers</p>
                    <p className="text-light text-start fw-medium fs-6 col-12 p-0 m-0 mb-5 mb-md-0"> 700 Own tractor units</p>

                </div>
            </div> 
                </div>            
            </div>            
        </div>
        </div>
    );
}


<div className=" col-md-12 mx-auto pt-2 pb-2">
<div className="row mt-5 pt-2 mb-5 pb-5">
    <div className="col-md-3 col-6 pt-5">
        <div className="counter">
            <div className="counter-content">
                <div className="counter-icon">
                    <i className="fa fa-globe"></i>
                </div>
                <h3>Ulaşılan Ülkeler</h3>
            </div>
           
        </div>
    </div>
    <div className="col-md-3 col-6 pt-5">
        <div className="counter green">
            <div className="counter-content">
                <div className="counter-icon">
                    <i className="fa fa-rocket"></i>
                </div>
                <h3>Ulaşılan Şehirler</h3>
            </div>
            <span className="counter-value">171</span>
        </div>
    </div>
    <div className="col-md-3 col-6 pt-5">
        <div className="counter blue">
            <div className="counter-content">
                <div className="counter-icon">
                    <i className="fa fa-user"></i>
                </div>
                <h3>Memnun Müşteriler</h3>
            </div>
            <span className="counter-value">6825</span>
        </div>
    </div>
    <div className="col-md-3 col-6 pt-5">
        <div className="counter gray">
            <div className="counter-content">
                <div className="counter-icon">
                    <i className="fa fa-user"></i>
                </div>
                <h3>Ulaşıtırılan kargolar</h3>
            </div>
            <span className="counter-value">27334</span>
        </div>
    </div>
</div>
</div>
export default Counters;
