import React from "react";

function Maps (){


    return(
<div>
<iframe
className="Maps"

 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3854.6086502031417!2d29.196421908497523!3d40.974098213281785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cacfcece18baa1%3A0x172b43366c59df36!2sMers%20Grup%20Lojistik%20T%C3%BCrkiye!5e1!3m2!1str!2str!4v1735041528526!5m2!1str!2str"  referrerpolicy="no-referrer-when-downgrade"></iframe>

</div>

    )
}

export default Maps;