import React from "react";

function AboutText(){


    return(      
      <div className="about"> 
        <div className="mx-auto bg-main-ins  h-100" >
          <div className="flow" style={{height:"80px"}}>
       
     </div>
            <div className="container-lg bg-transpanet text-center mx-auto" >
            <h1 className="mx-auto text-center highlighted-text fs-1 mt-5 " > Hakkımızda </h1>                  
            </div>
            <div className="col-11 col-md-8 mx-auto text-center row" >
        <div className="col-12 col-md-6 mt-5" >
        <p className="text-start"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,</p>
        <p className="text-start">  and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
         </div>
         <div className="col-12 col-md-6 mt-5" >
        <p className="text-start"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,</p>
        <p className="text-start">  and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
         </div>
            </div>
            <div className="flow" style={{height:"160px"}}>
       
       </div>
             </div>
        </div>
        
    )    
}

export default AboutText